import type { Slide } from '@/types/slides'

export const slides: Slide[] = [
  // {
  //   id: 'test-slide-1',
  //   elements: [
  //     {
  //       type: 'shape',
  //       id: '4cbRxp',
  //       left: 0,
  //       top: 200,
  //       width: 546,
  //       height: 362.5,
  //       viewBox: [200, 200],
  //       path: 'M 0 0 L 0 200 L 200 200 Z',
  //       fill: '#5b9bd5',
  //       fixedRatio: false,
  //       opacity: 0.7,
  //       rotate: 0
  //     },
  //     {
  //       type: 'shape',
  //       id: 'ookHrf',
  //       left: 0,
  //       top: 0,
  //       width: 300,
  //       height: 320,
  //       viewBox: [200, 200],
  //       path: 'M 0 0 L 0 200 L 200 200 Z',
  //       fill: '#5b9bd5',
  //       fixedRatio: false,
  //       flipV: true,
  //       rotate: 0
  //     },
  //     {
  //       type: 'text',
  //       id: 'idn7Mx',
  //       left: 355,
  //       top: 65.25,
  //       width: 585,
  //       height: 188,
  //       lineHeight: 1.2,
  //       content: '<p><strong><span style=\'font-size:  112px\'>PPTIST</span></strong></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333'
  //     },
  //     {
  //       type: 'text',
  //       id: '7stmVP',
  //       left: 355,
  //       top: 253.25,
  //       width: 585,
  //       height: 56,
  //       content: '<p><span style=\'font-size:  24px\'>基于 Vue 3.x + TypeScript 的在线演示文稿应用</span></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333'
  //     },
  //     {
  //       type: 'line',
  //       id: 'FnpZs4',
  //       left: 361,
  //       top: 238,
  //       start: [0, 0],
  //       end: [549, 0],
  //       points: ['', ''],
  //       color: '#5b9bd5',
  //       style: 'solid',
  //       width: 2,
  //     },
  //   ],
  //   background: {
  //     type: 'solid',
  //     color: '#ffffff',
  //   },
  // },
  {
    id: 'test-slide-2',
    elements: [
      {
        type: 'text',
        id: 'ptNnUJ',
        left: 145,
        top: 148,
        width: 711,
        height: 77,
        lineHeight: 1.2,
        content:
          "<p style='text-align: center;'><strong><span style='font-size: 48px'>在此处添加标题</span></strong></p>",
        rotate: 0,
        defaultFontName: 'Microsoft Yahei',
        defaultColor: '#333',
      },
      {
        type: 'text',
        id: 'mRHvQN',
        left: 207.50000000000003,
        top: 249.84259259259264,
        width: 585,
        height: 56,
        content:
          "<p style='text-align: center;'><span style='font-size: 24px'>在此处添加副标题</span></p>",
        rotate: 0,
        defaultFontName: 'Microsoft Yahei',
        defaultColor: '#333',
      },
      {
        type: 'line',
        id: '7CQDwc',
        left: 323.09259259259267,
        top: 238.33333333333334,
        start: [0, 0],
        end: [354.8148148148148, 0],
        points: ['', ''],
        color: '#5b9bd5',
        style: 'solid',
        width: 4,
      },
      {
        type: 'shape',
        id: '09wqWw',
        left: -27.648148148148138,
        top: 432.73148148148147,
        width: 1056.2962962962963,
        height: 162.96296296296296,
        viewBox: [200, 200],
        path: 'M 0 20 C 40 -40 60 60 100 20 C 140 -40 160 60 200 20 L 200 180 C 140 240 160 140 100 180 C 40 240 60 140 0 180 L 0 20 Z',
        fill: '#5b9bd5',
        fixedRatio: false,
        rotate: 0,
      },
    ],
    background: {
      type: 'solid',
      color: '#fff',
    },
  },
  // {
  //   id: 'test-slide-3',
  //   elements: [
  //     {
  //       type: 'text',
  //       id: 's0KVcDeFOs',
  //       left: 432.192123921085,
  //       top: 0,
  //       width: 154.130702836,
  //       height: 50,
  //       content: '<p>相关文献</p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#fff',
  //       vertical: false,
  //     },
  //     {
  //       content: "<p ><span style='font-size: 16px;'>最新文献：</span></p>",
  //       defaultColor: '#fff',
  //       defaultFontName: 'Microsoft Yahei',
  //       height: 50,
  //       id: 'JHDYEjEavi',
  //       left: 35.25994143033293,
  //       rotate: 0,
  //       top: 30.73366214549938,
  //       type: 'text',
  //       vertical: false,
  //       width: 154.13070283600496,
  //     },
  //     {
  //       type: 'text',
  //       id: 'kcY7zjbQoK',
  //       left: 52.25994143033293,
  //       top: 66.09124537607892,
  //       width: 867.5920930949445,
  //       height: 56,
  //       content:
  //         "<p><span style='font-size: 12px;'>2.BRAF-MEK Inhibition in Newly Diagnosed Papillary Craniopharyngiomas. \r\nN Engl J Med 2023;389:2. PMID: 37437144 \r\n, PubMed Central, doi: 10.1056/NEJMoa2213329. Cite'</span></p>",
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#fff',
  //       link: {
  //         target: 'https://pubmed.ncbi.nlm.nih.gov/36763550/',
  //         type: 'web',
  //       },
  //       lineHeight: 0.9,
  //       paragraphSpace: 0,
  //     },
  //     {
  //       content: "<p ><span style='font-size: 16px;'>系统评价：</span></p>",
  //       defaultColor: '#fff',
  //       defaultFontName: 'Microsoft Yahei',
  //       height: 50,
  //       id: 'Re7xmWvqCh',
  //       left: 29.516414919852036,
  //       rotate: 0,
  //       top: 180.66362900739827,
  //       type: 'text',
  //       vertical: false,
  //       width: 154.13070283600496,
  //     },
  //     {
  //       content: "<p ><span style='font-size: 16px;'>指南：</span></p>",
  //       defaultColor: '#fff',
  //       defaultFontName: 'Microsoft Yahei',
  //       height: 50,
  //       id: 'Zym-2PADj-',
  //       left: 24.516414919852036,
  //       rotate: 0,
  //       top: 352.54525662762023,
  //       type: 'text',
  //       vertical: false,
  //       width: 154.13070283600496,
  //     },
  //     {
  //       content:
  //         "<p><span style='font-size: 12px;'>2.BRAF-MEK Inhibition in Newly Diagnosed Papillary Craniopharyngiomas. \r\nN Engl J Med 2023;389:2. PMID: 37437144 \r\n, PubMed Central, doi: 10.1056/NEJMoa2213329. Cite'</span></p>",
  //       rotate: 0,
  //       defaultColor: '#fff',
  //       defaultFontName: 'Microsoft Yahei',
  //       height: 56,
  //       id: '2-PSXRp2_O',
  //       left: 52.25994143033293,
  //       lineHeight: 0.9,
  //       link: {
  //         target: 'https://pubmed.ncbi.nlm.nih.gov/36763550/',
  //         type: 'web',
  //       },
  //       paragraphSpace: 5,
  //       top: 149.66362900739827,
  //       type: 'text',
  //       vertical: false,
  //       width: 867.5920930949445,
  //     },
  //     {
  //       content:
  //         "<p><span style='font-size: 12px;'>2.BRAF-MEK Inhibition in Newly Diagnosed Papillary Craniopharyngiomas. \r\nN Engl J Med 2023;389:2. PMID: 37437144 \r\n, PubMed Central, doi: 10.1056/NEJMoa2213329. Cite'</span></p>",
  //       rotate: 0,
  //       defaultColor: '#fff',
  //       defaultFontName: 'Microsoft Yahei',
  //       height: 56,
  //       id: 'DrIhvGyO_Z',
  //       left: 47.25994143033293,
  //       lineHeight: 0.9,
  //       link: {
  //         target: 'https://pubmed.ncbi.nlm.nih.gov/36763550/',
  //         type: 'web',
  //       },
  //       paragraphSpace: 5,
  //       top: 223.81159448212085,
  //       type: 'text',
  //       vertical: false,
  //       width: 867.5920930949445,
  //     },
  //     {
  //       content:
  //         "<p><span style='font-size: 12px;'>2.BRAF-MEK Inhibition in Newly Diagnosed Papillary Craniopharyngiomas. \r\nN Engl J Med 2023;389:2. PMID: 37437144 \r\n, PubMed Central, doi: 10.1056/NEJMoa2213329. Cite'</span></p>",
  //       defaultColor: '#fff',
  //       defaultFontName: 'Microsoft Yahei',
  //       height: 56,
  //       id: 'QUOtjfh-K7',
  //       left: 48.25994143033293,
  //       lineHeight: 0.9,
  //       link: {
  //         target: 'https://pubmed.ncbi.nlm.nih.gov/36763550/',
  //         type: 'web',
  //       },
  //       paragraphSpace: 5,
  //       rotate: 0,
  //       top: 265.54346485819974,
  //       type: 'text',
  //       vertical: false,
  //       width: 867.5920930949445,
  //     },
  //     {
  //       content:
  //         "<p><span style='font-size: 12px;'>2.BRAF-MEK Inhibition in Newly Diagnosed Papillary Craniopharyngiomas. \r\nN Engl J Med 2023;389:2. PMID: 37437144 \r\n, PubMed Central, doi: 10.1056/NEJMoa2213329. Cite'</span></p>",

  //       defaultColor: '#fff',
  //       defaultFontName: 'Microsoft Yahei',
  //       height: 56,
  //       id: 'fJg1Wg5Bgt',
  //       left: 46.25994143033293,
  //       lineHeight: 0.9,
  //       link: {
  //         target: 'https://pubmed.ncbi.nlm.nih.gov/36763550/',
  //         type: 'web',
  //       },
  //       paragraphSpace: 5,
  //       rotate: 0,
  //       top: 304.54525662762023,
  //       type: 'text',
  //       vertical: false,
  //       width: 867.5920930949445,
  //     },
  //     {
  //       content:
  //         "<p><span style='font-size: 12px;'>2.BRAF-MEK Inhibition in Newly Diagnosed Papillary Craniopharyngiomas. \r\nN Engl J Med 2023;389:2. PMID: 37437144 \r\n, PubMed Central, doi: 10.1056/NEJMoa2213329. Cite'</span></p>",
  //       defaultColor: '#fff',
  //       defaultFontName: 'Microsoft Yahei',
  //       height: 56,
  //       id: '8TOtzFfmi9',
  //       left: 38.25994143033293,
  //       lineHeight: 0.9,
  //       link: {
  //         target: 'https://pubmed.ncbi.nlm.nih.gov/36763550/',
  //         type: 'web',
  //       },
  //       paragraphSpace: 5,
  //       rotate: 0,
  //       top: 393.1229963008631,
  //       type: 'text',
  //       vertical: false,
  //       width: 867.5920930949445,
  //     },
  //     {
  //       content:
  //         "<p><span style='font-size: 12px;'>2.BRAF-MEK Inhibition in Newly Diagnosed Papillary Craniopharyngiomas. \r\nN Engl J Med 2023;389:2. PMID: 37437144 \r\n, PubMed Central, doi: 10.1056/NEJMoa2213329. Cite'</span></p>",

  //       defaultColor: '#fff',
  //       defaultFontName: 'Microsoft Yahei',
  //       height: 56,
  //       id: 'AJuqUR9nhG',
  //       left: 38.25994143033293,
  //       lineHeight: 0.9,
  //       link: {
  //         target: 'https://pubmed.ncbi.nlm.nih.gov/36763550/',
  //         type: 'web',
  //       },
  //       paragraphSpace: 5,
  //       rotate: 0,
  //       top: 431.1229963008631,
  //       type: 'text',
  //       vertical: false,
  //       width: 867.5920930949445,
  //     },
  //     {
  //       content:
  //         "<p><span style='font-size: 12px;'>2.BRAF-MEK Inhibition in Newly Diagnosed Papillary Craniopharyngiomas. \r\nN Engl J Med 2023;389:2. PMID: 37437144 \r\n, PubMed Central, doi: 10.1056/NEJMoa2213329. Cite'</span></p>",

  //       defaultColor: '#fff',
  //       defaultFontName: 'Microsoft Yahei',
  //       height: 56,
  //       id: 'R6FCTjykYh',
  //       left: 39.25994143033293,
  //       lineHeight: 0.9,
  //       link: {
  //         target: 'https://pubmed.ncbi.nlm.nih.gov/36763550/',
  //         type: 'web',
  //       },
  //       paragraphSpace: 5,
  //       rotate: 0,
  //       top: 468.1229963008631,
  //       type: 'text',
  //       vertical: false,
  //       width: 867.592093094944,
  //     },
  //   ],
  //   background: {
  //     type: 'solid',
  //     color: '#fff',
  //   },
  // },
  // {
  //   id: 'test-slide-3',
  //   elements: [
  //     {
  //       type: 'shape',
  //       id: 'vSheCJ',
  //       left: 183.5185185185185,
  //       top: 175.5092592592593,
  //       width: 605.1851851851851,
  //       height: 185.18518518518516,
  //       viewBox: [200, 200],
  //       path: 'M 0 0 L 200 0 L 200 200 L 0 200 Z',
  //       fill: '#5b9bd5',
  //       fixedRatio: false,
  //       rotate: 0
  //     },
  //     {
  //       type: 'shape',
  //       id: 'Mpwv7x',
  //       left: 211.29629629629628,
  //       top: 201.80555555555557,
  //       width: 605.1851851851851,
  //       height: 185.18518518518516,
  //       viewBox: [200, 200],
  //       path: 'M 0 0 L 200 0 L 200 200 L 0 200 Z',
  //       fill: '#5b9bd5',
  //       fixedRatio: false,
  //       rotate: 0,
  //       opacity: 0.7
  //     },
  //     {
  //       type: 'text',
  //       id: 'WQOTAp',
  //       left: 304.9074074074074,
  //       top: 198.10185185185182,
  //       width: 417.9629629629629,
  //       height: 140,
  //       content: '<p style=\'text-align: center;\'><strong><span style=\'color: #ffffff;\'><span style=\'font-size: 80px\'>感谢观看</span></span></strong></p>',
  //       rotate: 0,
  //       defaultFontName: 'Microsoft Yahei',
  //       defaultColor: '#333',
  //       wordSpace: 5
  //     }
  //   ],
  //   background: {
  //     type: 'solid',
  //     color: '#fff',
  //   },
  // },
]

function originalUrl(originalUrl: any) {
  throw new Error('Function not implemented.')
}
