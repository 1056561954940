
export interface PresetTheme {
  background: string
  fontColor: string
  fontname: string
  colors: string[]
}
export interface PresetImgTheme {
  background: string
  fontColor: string
  fontname: string
  colors: string[]
}
export const PRESET_THEMES: PresetTheme[] = [
  {
    background: '#ffffff',
    fontColor: '#333333',
    fontname: 'Microsoft Yahei',
    colors: ['#5b9bd5', '#ed7d31', '#a5a5a5', '#ffc000', '#4472c4', '#70ad47'],
  },
  // {
  //   background: '#ffffff',
  //   fontColor: '#333333',
  //   fontname: 'Microsoft Yahei',
  //   colors: ['#83992a', '#3c9670', '#44709d', '#a23b32', '#d87728', '#deb340'],
  // },
  // {
  //   background: '#ffffff',
  //   fontColor: '#333333',
  //   fontname: 'Microsoft Yahei',
  //   colors: ['#e48312', '#bd582c', '#865640', '#9b8357', '#c2bc80', '#94a088'],
  // },
  // {
  //   background: '#ffffff',
  //   fontColor: '#333333',
  //   fontname: 'Microsoft Yahei',
  //   colors: ['#bdc8df', '#003fa9', '#f5ba00', '#ff7567', '#7676d9', '#923ffc'],
  // },
  // {
  //   background: '#ffffff',
  //   fontColor: '#333333',
  //   fontname: 'Microsoft Yahei',
  //   colors: ['#90c225', '#54a121', '#e6b91e', '#e86618', '#c42f19', '#918756'],
  // },
  // {
  //   background: '#ffffff',
  //   fontColor: '#333333',
  //   fontname: 'Microsoft Yahei',
  //   colors: ['#1cade4', '#2683c6', '#27ced7', '#42ba97', '#3e8853', '#62a39f'],
  // },
  // {
  //   background: '#e9efd6',
  //   fontColor: '#333333',
  //   fontname: 'Microsoft Yahei',
  //   colors: ['#a5300f', '#de7e18', '#9f8351', '#728653', '#92aa4c', '#6aac91'],
  // },
  // {
  //   background: '#17444e',
  //   fontColor: '#333333',
  //   fontname: 'Microsoft Yahei',
  //   colors: ['#b01513', '#ea6312', '#e6b729', '#6bab90', '#55839a', '#9e5d9d'],
  // },
  // {
  //   background: '#36234d',
  //   fontColor: '#333333',
  //   fontname: 'Microsoft Yahei',
  //   colors: ['#b31166', '#e33d6f', '#e45f3c', '#e9943a', '#9b6bf2', '#d63cd0'],
  // },
  // {
  //   background: '#247fad',
  //   fontColor: '#333333',
  //   fontname: 'Microsoft Yahei',
  //   colors: ['#052f61', '#a50e82', '#14967c', '#6a9e1f', '#e87d37', '#c62324'],
  // },
  // {
  //   background: '#103f55',
  //   fontColor: '#333333',
  //   fontname: 'Microsoft Yahei',
  //   colors: ['#40aebd', '#97e8d5', '#a1cf49', '#628f3e', '#f2df3a', '#fcb01c'],
  // },
  // {
  //   background: '#242367',
  //   fontColor: '#333333',
  //   fontname: 'Microsoft Yahei',
  //   colors: ['#ac3ec1', '#477bd1', '#46b298', '#90ba4c', '#dd9d31', '#e25345'],
  // },
  // {
  //   background: '#e4b75e',
  //   fontColor: '#333333',
  //   fontname: 'Microsoft Yahei',
  //   colors: ['#f0a22e', '#a5644e', '#b58b80', '#c3986d', '#a19574', '#c17529'],
  // },
  // {
  //   background: '#ffffff',
  //   fontColor: '#333333',
  //   fontname: 'Microsoft Yahei',
  //   colors: ['#bdc8df', '#003fa9', '#f5ba00', '#ff7567', '#7676d9', '#923ffc'],
  // },
]

export const PRESET_IMG_THEMES: PresetImgTheme[] = [
  {
    background: 'https://oss.perspcloud.com/ppt/bg_img/1.png',
    fontColor: '#ffffff',
    fontname: 'Microsoft Yahei',
    colors: ['#5b9bd5', '#ed7d31', '#a5a5a5', '#ffc000', '#4472c4', '#70ad47'],
  },
  {
    background: 'https://oss.perspcloud.com/ppt/bg_img/2.png',
    fontColor: '#ffffff',
    fontname: 'Microsoft Yahei',
    colors: ['#5b9bd5', '#ed7d31', '#a5a5a5', '#ffc000', '#4472c4', '#70ad47'],
  },
  {
    background: 'https://oss.perspcloud.com/ppt/bg_img/3.png',
    fontColor: '#ffffff',
    fontname: 'Microsoft Yahei',
    colors: ['#5b9bd5', '#ed7d31', '#a5a5a5', '#ffc000', '#4472c4', '#70ad47'],
  },
  {
    background: 'https://oss.perspcloud.com/ppt/bg_img/4.png',
    fontColor: '#ffffff',
    fontname: 'Microsoft Yahei',
    colors: ['#5b9bd5', '#ed7d31', '#a5a5a5', '#ffc000', '#4472c4', '#70ad47'],
  },
  {
    background: 'https://oss.perspcloud.com/ppt/bg_img/5.png',
    fontColor: '#ffffff',
    fontname: 'Microsoft Yahei',
    colors: ['#5b9bd5', '#ed7d31', '#a5a5a5', '#ffc000', '#4472c4', '#70ad47'],
  },
  {
    background: 'https://oss.perspcloud.com/ppt/bg_img/6.png',
    fontColor: '#ffffff',
    fontname: 'Microsoft Yahei',
    colors: ['#5b9bd5', '#ed7d31', '#a5a5a5', '#ffc000', '#4472c4', '#70ad47'],
  },
  {
    background: 'https://oss.perspcloud.com/ppt/bg_img/7.png',
    fontColor: '#ffffff',
    fontname: 'Microsoft Yahei',
    colors: ['#5b9bd5', '#ed7d31', '#a5a5a5', '#ffc000', '#4472c4', '#70ad47'],
  },
  {
    background: 'https://oss.perspcloud.com/ppt/bg_img/8.png',
    fontColor: '#ffffff',
    fontname: 'Microsoft Yahei',
    colors: ['#5b9bd5', '#ed7d31', '#a5a5a5', '#ffc000', '#4472c4', '#70ad47'],
  },
  {
    background: 'https://oss.perspcloud.com/ppt/bg_img/9.png',
    fontColor: '#ffffff',
    fontname: 'Microsoft Yahei',
    colors: ['#5b9bd5', '#ed7d31', '#a5a5a5', '#ffc000', '#4472c4', '#70ad47'],
  },
]
